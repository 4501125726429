<template>
  <div>
    <page-header>
      <template v-slot:right>
        <el-button type="primary" @click="handleBindUser()">绑定用户</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <div class="condition">
          <el-tabs v-model="searchForm.userType" @tab-click="handleTabsClick">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane label="免费" name="1"></el-tab-pane>
            <el-tab-pane label="VIP(一年)" name="2"></el-tab-pane>
            <el-tab-pane label="VIP(三年)" name="3"></el-tab-pane>
            <el-tab-pane label="即将到期(三个月)" name="4"></el-tab-pane>
          </el-tabs>
        </div>
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm">
              <el-form-item>
                <el-input v-model="searchForm.text" placeholder="用户昵称/姓名/手机号" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.amsUserDisplayName" placeholder="所属员工姓名" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.amsDeptId" placeholder="所属员工部门" clearable>
                  <el-option v-for="item in deptList" :key="item.id" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    start-placeholder="首次开通日期起"
                    end-placeholder="首次开通日期止"
                    value-format="yyyy-MM-dd"
                    class="condition-item"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button plain @click="handleSearch">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" width="55"></el-table-column>
              <el-table-column prop="nickName" label="微信昵称" width="300">
                <template slot-scope="scope">
                  {{ scope.row.nickName }}<i v-if="scope.row.isVip === 1" class="fa-solid fa-chess-queen"
                                             style="color: red;margin-left: 5px"></i>
                </template>
              </el-table-column>
              <el-table-column prop="mobile" label="手机号" width="100"></el-table-column>
              <el-table-column prop="creationTime" label="注册时间" width="140"></el-table-column>
              <el-table-column prop="vipFirstOpenTime" label="首次开通时间" width="140"></el-table-column>
              <el-table-column prop="vipExpirationTime" label="VIP到期时间" width="140"></el-table-column>
              <el-table-column prop="amsUserDisplayName" label="所属员工"></el-table-column>
              <el-table-column prop="amsUserCreatorDeptName" label="所属员工部门" width="200"></el-table-column>
              <el-table-column prop="companyName" label="公司名称" width="200"></el-table-column>
              <el-table-column prop="companyAddress" label="地址" width="400"></el-table-column>
              <el-table-column
                  label="操作"
                  fixed="right"
                  width="80">
                <template slot-scope="scope">
                  <router-link :to="`/user/edit?id=${scope.row.id}`">
                    <el-button type="text">编辑</el-button>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
            <el-dialog
                title="绑定用户"
                :visible.sync="dialogVisible"
                width="40%">
              <el-input placeholder="请输入微信昵称或手机号搜索" v-model="searchUserText">
                <el-button slot="append" icon="el-icon-search" @click="handleSearchUser"></el-button>
              </el-input>
              <div class="user-search-result">
                <el-table
                    :data="bindUsers"
                    v-loading="searchUserLoading"
                    style="width: 100%">
                  <el-table-column prop="nickName" label="昵称"></el-table-column>
                  <el-table-column prop="mobile" label="手机号"></el-table-column>
                  <el-table-column prop="creationTime" label="注册时间" width="140"></el-table-column>
                </el-table>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button plain @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleBindUserSubmit" :loading="loadingBindSubmit">确定</el-button>
              </div>
            </el-dialog>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { SysDeptApi, UserApi } from '@/api'
import { SHORTCUTS } from '@/utils/contants'

export default {
  name: 'UserList',
  data () {
    return {
      searchForm: {
        text: '',
        amsUserDisplayName: '',
        amsDeptId: '',
        userType: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      dialogVisible: false,
      loadingBindSubmit: false,
      searchUserText: '',
      bindUsers: [],
      searchUserLoading: false,
      deptList: [],
      pickerOptions: {
        shortcuts: SHORTCUTS
      },
      dateRange: []
    }
  },
  methods: {
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.text) {
        params.text = this.searchForm.text
      }

      if (this.searchForm.amsUserDisplayName) {
        params.amsUserDisplayName = this.searchForm.amsUserDisplayName
      }

      if (this.searchForm.amsDeptId) {
        params.amsDeptId = this.searchForm.amsDeptId
      }

      if (this.searchForm.userType) {
        params.userType = this.searchForm.userType
      }

      UserApi.list(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleSearchUser () {
      if (this.searchUserText) {
        this.searchUserLoading = true
        UserApi.search(this.searchUserText).then(res => {
          this.searchUserLoading = false
          if (res) {
            this.bindUsers = [res]
          } else {
            this.bindUsers = []
          }
        })
      }
    },
    handleBindUser () {
      this.dialogVisible = true
      this.searchUserText = ''
      this.bindUsers = []
    },
    handleBindUserSubmit () {
      if (this.bindUsers.length !== 1) {
        this.$message.error('没有可绑定的用户')
        return
      }
      UserApi.bindUser({ memberId: this.bindUsers[0].id }).then(() => {
        this.dialogVisible = false
        this.handleCurrentChange(1)
        this.$message.success('绑定成功')
      }).catch(() => {
        this.dialogVisible = false
      })
    },
    handleTabsClick () {
      this.handleCurrentChange(1)
    }
  },
  created: function () {
    if (this.$route.query.type) {
      this.searchForm.userType = this.$route.query.type
    }
    this.handleSearch()
    SysDeptApi.getAll().then(res => {
      this.deptList = res
    })
  }
}
</script>

<style lang="less">

.user-search-result {
  margin-top: 10px;
}

</style>
